import React from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css';
import Logo from "../images/logo.png";

const Navbar = () => {
  return (
    <div className="navbar">
      <div className="navbar-content">
        <div className="logo-container">
          <div className="logo">
            <img src={Logo} alt="logo" />
          </div>
          <div className="links">
            <Link to="/">Home</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;


