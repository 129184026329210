import React from 'react';
import './Details.css';

const Details = () => {
  return (
    <div className="details-section">
      <div className="details-header">
        <h2>College: After Dusk</h2>
      </div>
      <div className="details-content">
        <div className="podcast-info">
          <p>Welcome to the meeting point of academia and real-world dialogue. Hosted by Matt Miller, a highly driven entrepreneur and full-time student, College After Dark is your space to break down the traditional barriers of the academic world.</p>
        </div>
        <div className="podcast-cards">
          <div className="card">
            <h3>Barrier-Breaking Conversations</h3>
            <p>We're dismantling the hierarchical student-professor dynamic to foster mutual understanding and respect.</p>
          </div>
          <div className="card">
            <h3>Deep Insights</h3>
            <p>Capture rare wisdom from professors that you won't find in lecture halls, facilitated by a host who understands both sides of the academic coin.</p>
          </div>
          <div className="card">
            <h3>Two-Way Learning Experience</h3>
            <p>It's not just about absorbing information; join in on live Q&A sessions to interact directly with our expert guests.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;


