import React from 'react';
import Hero from '../HeroComponent/Hero';
import Details from '../DetailsComponent/Details';
import './HomePage.css';
import Form from "../FormComponent/Form"

const HomePage = () => {
  return (
    <div className="home">
      <Hero />
      <Details />
      <Form />
    </div>
  );
};

export default HomePage;
