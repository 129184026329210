import React from 'react';
import './Hero.css';
import Headshot from '../../../images/headshot.jpg';  // Replace this with the path to your headshot image

const Hero = () => {
  return (
    <div className="hero">
      <div className="image-container">
        <img src={Headshot} alt="Headshot" />
      </div>
      <div className="text-bubble">
        <p>Welcome to College After Dusk. I'm Matt Miller—entrepreneur, coder, and full-time student. We're breaking down academic walls to connect professors and students like never before. If you're a professor looking to really understand today's student, this is where you want to be.</p>
      </div>
    </div>
  );
};

export default Hero;

