import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/NavBar';
import HomePage from './pages/HomePage/HomePageMain/HomePage';  // Import HomePage
import Footer from "./components/Footer"
import Videos from "./pages/Content/ContentMain/Content"


function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="services" element={<Videos />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;

