// src/components/Content.js

import React from 'react';
import './Content.css';

const Content = () => {
  // Expanded placeholder for video URLs, to be replaced with real data
  const videoLinks = [null, null, null, null, null, null]; 

  return (
    <div className="content">
      {videoLinks.map((link, index) => (
        <div key={index} className="video-container">
          {link ? (
            <iframe
              width="300"
              height="200"
              src={`https://www.youtube.com/embed/${link}`}
              title={`video-${index}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ) : (
            <div className="placeholder">No Video</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Content;

