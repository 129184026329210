import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="simple-footer">
      <div className="simple-footer__content">
        <ul className="simple-footer__list">
          <li><Link to="/" onClick={() => window.scrollTo(0, 0)}>Home</Link></li>
        </ul>
        <p className="simple-footer__credits">Crafted with precision by Matt Miller</p>
      </div>
    </footer>
  );
};

export default Footer;
