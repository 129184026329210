import React from 'react';
import './Form.css';

const Form = () => {
  return (
    <div className="form-section">
      <div className="form-wrapper">
        <div className="form-container">
          <h2>Contact Us</h2>
          <form className="contact-form">
            <div className="input-group">
              <label htmlFor="name">Name</label>
              <input type="text" id="name" name="name" required />
            </div>
            <div className="input-group">
              <label htmlFor="email">Email</label>
              <input type="email" id="email" name="email" required />
            </div>
            <div className="input-group">
              <label htmlFor="message">Message</label>
              <textarea id="message" name="message" required></textarea>
            </div>
            <button type="submit" className="submit-button">Submit</button>
          </form>
        </div>
        <div className="bubble">
          <h3>Why Contact Us?</h3>
          <p>We value your feedback and questions. Feel free to reach out!</p>
        </div>
      </div>
    </div>
  );
};

export default Form;

